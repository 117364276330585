import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../assets/sass/main.scss"

export default function Layout ({ hero, children, className = '' }) {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="page-wrapper" className={className}>
      <Header siteTitle={site.siteMetadata.title} hero={hero} />
      
      {children}
      
      <Footer />
    </div>
  )
}
