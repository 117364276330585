import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function ProduktePage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Produkte" description="Düngemittelsubstrat und Biokohle" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Produkte</h2>
            </header>

            <div className="row features">
              <section className="col-6 col-12-narrower feature">
                <div className="image-wrapper first">
                  <div className="image featured first">
                    <Link to="/produkte/duengemittelsubstrat/">
                      <Image name="sewagePlant" />
                    </Link>
                  </div>
                </div>
                <h3>Düngemittelsubstrat</h3>
                <p>
                  Phosphor-Rückgewinnung aus Klärschlamm
                </p>
                <p>
                  Schärfere Grenzwerte, Pflicht zum Phosphor-Recycling, Steigende Entsorgungskosten durch Kapazitätsengpässe: Die Verwertung von Klärschlamm wird immer anspruchsvoller!
                </p>
                <ul className="actions">
                  <li><Link to="/produkte/duengemittelsubstrat/" className="button">weiterlesen</Link></li>
                </ul>
              </section>
              <section className="col-6 col-12-narrower feature">
                <div className="image-wrapper first">
                  <div className="image featured first">
                    <Link to="/produkte/biokohle/">
                      <Image name="plants" />
                    </Link>
                  </div>
                </div>
                <h3>Biokohle</h3>
                <p>
                  Pflanzenkohle / Futterkohle / Aktivkohle
                </p>
                <p>
                  Biokohle lässt sich auf vielfältige Art und Weise einsetzen. Pflanzenkohle ist äußerst porös und besitzt eine riesige Oberfläche von bis zu 300 Quadratmetern pro Gramm.
                </p>
                <ul className="actions">
                  <li><Link to="/produkte/biokohle/" className="button">weiterlesen</Link></li>
                </ul>
              </section>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}



function Image({ name }) {
  const result = useStaticQuery(graphql`
    query {
      sewagePlant: file(relativePath: { eq: "ivan-bandura-762728-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 800) { ...GatsbyImageSharpFluid } }
      }

      plants: file(relativePath: { eq: "daniel-hjalmarsson-269425-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 800) { ...GatsbyImageSharpFluid } }
      }
    }
  `)

  return <Img fluid={result[name].childImageSharp.fluid} />
}
