import React from 'react';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer id="footer-wrapper">
      <div id="footer" className="container">
        <ul className="menu">
          <li><Link to="/impressum/" className="">Impressum</Link></li>
          <li><Link to="/datenschutz/" className="">Datenschutz</Link></li>
        </ul>
      </div>
      <div id="copyright" className="container">
        <ul className="menu">
          <li>&copy; TRV Projektentwicklung UG. Alle Rechte vorbehalten.</li>
          <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
        </ul>
      </div>
    </footer>
  )
}
